import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import Grid from "../Layout/Grid"
import FeaturedWork from "../Layout/FeaturedWork"

const WorkWrapper = styled.section`
  background: var(--light-gray);
  padding-bottom: 74px;

  @media screen and (min-width: 800px) {
    background: unset;
  }
  .background {
    position: sticky;
    top: 59px;
    background: rgba(34, 42, 53, 0.09);
    height: 90vh;
    z-index: -1;
  }

  .content {
    margin-top: -85vh;
    padding: 0rem 1rem;

    @media screen and (min-width: 800px) {
      padding: 0rem 9.5rem;
    }

    .section-title {
      color: var(--navy);
      font-family: "DIN Next LT Pro Bold", sans-serif;
      font-size: 40px;

      @media screen and (min-width: 800px) {
        font-size: 60px;
      }
    }
  }
`

const FP03Work = () => {
  const data = useStaticQuery(graphql`
    query {
      contentfulFeatured(listName: { eq: "Homepage" }) {
        id
        caseStudies {
          id
          slug
          featuredTitle
          featuredTagline
          client {
            title
          }
          featuredImage {
            fluid(quality: 100) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  `)

  const { caseStudies } = data.contentfulFeatured

  return (
    <WorkWrapper>
      <Grid>
        <div className="background"></div>
        <div className="content">
          <h1 className="section-title">Our Work</h1>
          <div className="work-wrapper">
            {caseStudies.map((caseStudy, index) => {
              const {
                id,
                slug,
                featuredTitle,
                client,
                featuredImage,
                featuredTagline,
              } = caseStudy

              return (
                <FeaturedWork
                  key={id}
                  url={slug}
                  title={featuredTitle}
                  subtitle={client.title}
                  tagline={featuredTagline}
                  image={featuredImage.fluid}
                  orientation={index % 2 === 0 ? "left" : "right"}
                  titleColour="black"
                />
              )
            })}
          </div>
        </div>
      </Grid>
    </WorkWrapper>
  )
}

export default FP03Work
